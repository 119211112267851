import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface AddressDetailsState {
  initialAddress?: any
  municipalityOptions?: string[]
}

const initialState: AddressDetailsState = {
  initialAddress: {}
};

const addressDetailsSlice = createSlice({
  name: "addressDetails",
  initialState,
  reducers: {
    saveInitialAddress(state, action: PayloadAction<any>) {
      state.initialAddress = action.payload;
    },
    setMunicipalityOptions(state, action: PayloadAction<string[]>) {
      state.municipalityOptions = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default addressDetailsSlice.reducer;
export const {
  saveInitialAddress,
  destroy
} = addressDetailsSlice.actions;
export const addressDetailsActions = addressDetailsSlice.actions;
