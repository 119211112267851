// @ts-nocheck

import { replace } from "lodash";
import PropTypes from "prop-types";
import React, { Component, useState } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";

import { Icon, Button } from "../ui";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState, returnHasValueOrFocused } from "./formMetaHelpers";

import * as styles from "./InputField.styles";

function InputField({
  meta,
  input,
  help,
  label,
  floating = true,
  inputLeft,
  inputRight,
  fieldProps = {},
  inputProps = {},
  phone,
  ...restProps
}) {

  const [ isHidden, setIsHidden ] = useState(true);

  const toggleShowPassword = () => {
    setIsHidden(val => !val);
  };

  const id = generateFieldId("input", meta, restProps, input);
  const { isInvalid, isValid } = returnFieldState(meta);
  const hasValueOrFocused = returnHasValueOrFocused(input.value, meta);
  const hasFocused = meta.active;
  const isPassword = inputProps.type === "password";

  return (
    <FormField
      className="form-field field-input"
      isInvalid={isInvalid}
      {...fieldProps}>

      <FormWrapper
        className="form-wrapper"
        floating={floating}
        isInvalid={isInvalid}
        isValid={isValid}
        hasValueOrFocused={hasValueOrFocused || inputProps.type === "date"}
        hasFocused={hasFocused}
        inputLeft={inputLeft}
        inputRight={inputRight}>

        <FormLabel
          className="form-label"
          id={id}>
          {label}
        </FormLabel>
        <div
          css={[
            styles.base,
            hasFocused && styles.hasFocused,
            isInvalid && styles.error,
            isValid && styles.success
          ]}>

          {
            phone &&
              <span css={[
                styles.phoneFormatLabel,
                hasFocused && styles.hasFocused,
                isInvalid && styles.error,
                isValid && styles.success
              ]}>{(!floating || hasValueOrFocused) && "+52"}</span>
          }

          <input
            id={id}
            readOnly={meta.submitting}
            css={[
              styles.input,
              phone && styles.phoneInput
            ]}
            {...inputProps}
            placeholder={(floating && !hasValueOrFocused) ? "" : inputProps.placeholder}
            type={isPassword ? (isHidden ? "password" : "text") : inputProps.type}
            {...input} />
          {isPassword &&
          <Button
            unstyled
            style={{
              margin: "0 10px 0 5px"
            }}
            tabIndex="-1"
            type="button"
            onClick={toggleShowPassword}
            icon={<Icon size="24" name={`eye${isHidden ? "" : "-slash"}`} />} />}
        </div>

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid} />

    </FormField>
  );
}


InputField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  inputLeft: PropTypes.object,
  inputRight: PropTypes.object,
  digitsOnly: PropTypes.bool,
  floating: PropTypes.bool,
  validate: PropTypes.array,
  label: PropTypes.string,
  help: PropTypes.node,
  phone: PropTypes.bool
};



type ComponentPropsType = {
  name: string;
  id: string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  fieldProps: Object;
  digitsOnly: boolean;
  floating: boolean;
  inputProps: React.InputHTMLAttributes;
  phone: boolean;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  static Input = InputField;

  digitsOnlyNormalizer = (val) => replace(val, /\D+/g, "");

  render() {
    return (
      <Field
        component={InputField}
        {...this.props}
        {...(this.props.digitsOnly ? {
          normalize: this.digitsOnlyNormalizer
        } : {})} />
    );
  }
}

// export default InputField;
